import Glide from '@glidejs/glide';
import { initIncrementableInputs } from './components/incrementable-input';

export function initBuyPopupProductsCarousel(selector) {
    const carousel = document.querySelectorAll(selector);

    if (!carousel) {
        return;
    }

    carousel.forEach((element) => {
        const slidesPhysicalCount = element.querySelectorAll('.glide__slide').length;

        const perViewHandled = (items) => (items <= slidesPhysicalCount ? items : slidesPhysicalCount);

        const toggleArrows = () => {
            if (productSlider.settings.perView < slidesPhysicalCount) {
                element.querySelector('.product-slider .product-slider__arrows').classList.remove('d-none');
            } else {
                element.querySelector('.product-slider .product-slider__arrows').classList.add('d-none');
            }
        };

        // eslint-disable-next-line no-unused-vars
        const isDragable = () => {};

        const productSlider = new Glide(element, {
            autoplay: false,
            perView: perViewHandled(2),
            breakpoints: {
                992: {
                    perView: perViewHandled(1),
                },
            },
            type: 'carousel',
            gap: 0,
            rewind: false,
        });

        productSlider.on(['resize', 'mount.before'], () => {
            toggleArrows();
        });

        productSlider.mount();
    });
}

// Inicializace carouselů produktů pri nakupu.
document.addEventListener('DOMContentLoaded', () => {
    naja.snippetHandler.addEventListener('afterUpdate', (event) => {
        if (event.detail.snippet.id === 'snippet--modalAfterPurchase') {
            initIncrementableInputs('#snippet--modalAfterPurchase');

            const modal = document.getElementById('after-purchase-modal');

            if (!modal) {
                return;
            }

            modal.addEventListener('shown.bs.modal', () => {
                initBuyPopupProductsCarousel('#after-purchase-modal .product-slider-after-purchase');
            });
        }
    });
});
